.keyart__bg {
  background-image: var(--bg-image-mobile);
}

@media (min-width: 480px) {
  .keyart__bg {
    background-image: var(--bg-image-tablet);
  }
}
@media (min-width: 768px) {
  .keyart__bg {
    background-image: var(--bg-image-large);
  }
}
@media (min-width: 1201px) {
  .keyart__bg {
    background-image: var(--bg-image-hd);
  }
}